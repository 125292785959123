import "./HomepageTab.css";
import { Link } from "react-router-dom";
import table from "./image/table1.png";
import artist from "./image/artist.png";
import group1 from "./image/package.png";
import EventImage from "./image/event.png";
import LazyLoad from "react-lazy-load";

export default function HomePageTab()
{
    return(
      <LazyLoad offset={5}>
      <div className="services">
        <Link
          to="/party-packages-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={group1} alt="Birthday Party Packages Near Me" />
            <p>Party Package</p>
          </div>
        </Link>
        <Link
          to="/book-a-table-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={table} alt="Table Booking" />
            <p>Book a Table</p>
          </div>
        </Link>
        <Link
          to="/event-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={EventImage} alt="Events" />
            <p>Events</p>
          </div>
        </Link>
        <Link
          to="/artist-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={artist} alt="Table Booking" />
            <p>Artist</p>
          </div>
        </Link>
      </div>
      </LazyLoad>
    );
}