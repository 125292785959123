import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import Googleplay from "./image/Googleplay.png";
import Applestore from "./image/appdownloads.png"

function Footer() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="footer-card--1">
        <div className="footer-card">
          <div className="footer">
            <div className="footer-content">
              <div className="logo1">
                <h2>PARTY WITTY</h2>
              </div>
                <div class="footer-content2">
                <div className="footer-anchor">
                  <a href="https://www.facebook.com/party.witt?mibextid=ZbWKwL">
                    <FaFacebookF />
                  </a>
                  <a href="https://instagram.com/partywitty_?igshid=NzZhOTFlYzFmZQ==">
                    <BsInstagram />
                  </a>
                  <a href="https://wa.me/918287213372">
                    <BsWhatsapp />
                  </a>
                </div>
              </div>
            </div>

            <div className="footer-content">
              <h3>Menu</h3>
              <ul>
                <Link to="/PartyWitty-Home">
                  <li>HOME</li>
                </Link>
              </ul>
              <ul>
                <Link to="/party-witty-aboutus">
                  <li>ABOUT US</li>
                </Link>
              </ul>
              <ul>
                <Link to="/services">
                  <li>SERVICES</li>
                </Link>
              </ul>
              <ul>
                <Link to="/blog">
                  <li>BLOG</li>
                </Link>
              </ul>
            
              <Link to="/our-contact-page">
                <ul>
                  <li>CONTACT US</li>
                </ul>
              </Link>
              <Link to="/club-detail">
                <ul>
                  <li>Club-detail-page</li>
                </ul>
              </Link>
            </div>
          
            <div className="footer-content">
              <h3>Agreements</h3>

              <ul>
                <Link to="/club--about--us">
                  <li>CLUB AGREEMENT</li>
                </Link>
              </ul>
              <ul>
                <Link to="/artist-agreement">
                  <li>ARTIST AGREEMENT</li>
                </Link>
              </ul>
              <ul>
                <Link to="/refund-policy">
                  <li>REFUND & CANCELLATION POLICY</li>
                </Link>
              </ul>
              <ul>
                <Link to="/privacy-policy">
                  <li>PRIVACY POLICY</li>
                </Link>
              </ul>

              <ul>
                <Link to="/term-conditions-policy">
                  <li>TERMS & CONDITION</li>
                </Link>
              </ul>
            </div>
            <div className="footer-content--1">
              <h3>Partner with Us</h3>
              <ul className="footer-content-ul1">
                <Link to="/as-an-artist">
                  <li>AS AN ARTIST</li>
                </Link>
              </ul>
              <ul className="footer-content-ul2">
                <Link to="/Product-Pricing">
                  <li classname="footer--as-a-buisness">AS A BUSINESS </li>
                </Link>
              </ul>
           
            </div>
            <div className="footer-content">
              <h3>Download</h3>
              <div className="playstore">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore" />
                </Link>
                <Link to="https://apps.apple.com/in/app/partywitty/id6478442502">
                  <img src={Applestore} alt="appstore"/>
                </Link>
              </div>
            </div>
          </div>
          <div class="footer-card2">
            <div class="footer-card2--1">
            <Link to="/term-conditions-policy">
              <p>Terms & Conditions</p></Link>
              <Link to="/privacy-policy">
              <p>Privacy Policy</p></Link>
            </div>
            <Link to="/">
            <p>PARTYWITTY.COM</p></Link>
          </div>
        </div>
      </div>
      <div className="footer-mobile-view">
        <div className="footer-social-links">
          <div class="footer-content2">
            <div className="footer--content--1--2">
              <h1 className="follow-header">PARTY WITTY</h1>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/party.witt?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://instagram.com/partywitty_?igshid=NzZhOTFlYzFmZQ=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram />
                </a>
                <a href="https://wa.me/918287213372" target="_blank"  rel="noreferrer">
                  <BsWhatsapp />
                </a>
              </div>
            </div>

            <div className="menu-links">
              <Link to="/" className="menu-links-links">
                Home
              </Link>

              <Link to="/party-witty-aboutus" className="menu-links-links">
                About us
              </Link>

              <Link to="/services" className="menu-links-links">
                Services
              </Link>

             
                <Link to="/blog">
                  <li>Blog</li>
                </Link>
              
              <Link to="/our-contact-page" className="menu-links-links">
                Contacts us
              </Link>
            

              <h1 className="partner">Agreements</h1>
              <Link to="/club-agreement"  className="menu-links-links">
                  <li>Club Agreement</li>
                </Link>
                <Link to="/artist-agreement" className="menu-links-links">
                  <li>Artist Agreement</li>
                </Link>
                <Link to="/refund-policy" className="menu-links-links">
                  <li>Refund & Cancellation Policy</li>
                </Link>
                <Link to="/privacy-policy" className="menu-links-links">
                  <li>Privacy Policy</li>
                </Link>
            
                <h1 className="partner">Partner with Us</h1>
              <Link to="/as-an-artist" className="menu-links-links">
                As an Artist
              </Link>

              <Link to="/Product-Pricing" className="menu-links-links">
                As a Buisness
              </Link>

            
           

              <div className="playstore-mobile">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore"/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Applestore} alt="appstore"/>
                </Link>
              </div>
            </div>
            <div class="footer-btm">
              <p>© AMIRAG HOSPITALITY (OPC) PVT. LTD., All Right Reserved.</p>
              <p>Designed By PARTY WITTY </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
