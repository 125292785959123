import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import App from "./App";
import OurArtist from "./Ourartist/OurArtist";
import Clubpage from "./Club-page/Clubpage";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import RefundPolicy from "./Club Agreement/Refundpolicy";
import ArtistAgreement from "./Club Agreement/Artist-agreement";
import ClubAgreement from "./Club Agreement/Agreement";
import Asanartist from "./Footer section page/Asanartist";
import AboutusPage from "./Footer section page/Aboutuspage";
import BookanArtist from "./Footer section page/bookanartist";
import PartnerwithUs from "./Footer section page/partnerwithus";
import Services from "./Footer section page/Services";
import ContactUs from "./Footer section page/contactus";
import Happeningparty from "./Happening Party page/Happeningparty";
import HappeningViewButton from "./Happening-paty-view-button/Happening-view";
import MusicViewButton from "./Happening-paty-view-button/music-zoner-view";
import CommingSoon from "./Happening-paty-view-button/comming-soon";
import RegistrationLoginForm from "./club-registration-page/registration";

import Privacypolicy from "./Club Agreement/Privacypolicy";
import Termscondition from "./Club Agreement/Termsandcondition";
import Cancellationpolicy from "./Club Agreement/Cancellationpolicy";
import Paymentpage from "./Paymentpage";
import Successpage from "./Successpage";
import ThankewPage from "./Thankewpage/Thankew-page";
import Favclub from "./Favorate Component/Userfavclub";
import PackagePrice from "./PackagePrice/PackagePrice";
import Clubaboutuspagesearch from "./Club-aboutus/Club-aboutussearch";
import Fireworks from "./Homepage/Newyearpagelight";
import MyBooking from "./hamburger--content/My--Booking";
import MyProfile from "./hamburger--content/My--Profile";
import MyNotification from "./hamburger--content/My--Notification";
import MyFavorite from "./hamburger--content/My--favorate";
import NewThankewPage from "./New--Thankew--page/NewThanke--page";
import FavorateHangoutViewall from "./favorate--hangout--page/Favorate-Hangout";
import TableBookingViewall from "./favorate--hangout--page/TableBooking--view--all";
import GroupBookingViewall from "./favorate--hangout--page/GroupBookingViewall";
import PopularPackageViewall from "./favorate--hangout--page/PopularPackage--View--all";
import FavorateHangoutThumbnail from "./favorate--hangout--page/Favorate--hangout--thumbnail";
import PopularPackageView from "./favorate--hangout--page/Popular--Package--view--all";
import PackageAboutUs from "./Club About Us/Package--about";
import BookATableViewAll from "./favorate--hangout--page/Book-a-table-view-all";
import ClubDetails from "./Club About Us/Club_full_Details";
import HoliFailed from "./New--Thankew--page/Holi-Failed-page";
import EventViewAll from "./event-view-all-page/eventview";
import EventAboutUS from "./event-view-all-page/event-about-us";
import SearchResult from './searchResult'
import ClubFullDetails from './Club About Us/Club_full_Details'
import Blog from "./blog-page/blog";
import BlogDetails from "./blog-page/Blogdetails.js";
import NewlyAddedClubViewAll from "./favorate--hangout--page/NewlyAddedViewAll.js";
import EventBookNow from "./event-view-all-page/Event-booknow.js";
import ArtistCard from "./Artist Component/Card/Artist.js";
import MyBookingHistory from "./BookingHistory/MyBooking.js";
import ClubDetail from "./Club Detail Component/index.js";



function NavbarConditional() {
  
  const location = useLocation();
  useEffect(() => {
    const titleMap = {
      "/": "Party Packages | Corporate Party Places, Cocktail Party Bar,Pubs",
      "/artist-page": "PartyWitty - Our Artists",
      "/club-about-us": "PartyWitty - Club About Us",
      "/BookATableViewAll": "PartyWitty - Book a Table",
    };
    document.title = titleMap[location.pathname] || "PartyWitty";
  }, [location.pathname]);
  const hideNavbarRoutes = [
    "/packagedetailspage",
    "/as-club",
    "/booknow--table--cart",
    "/my--booking",
    "/my-Favorite--ham",
    "/booknow--table--cart/[^/]+$",
    "/nextPage",
    "/summerypage",
    "/my--profile",
    "/tablebooking--pass/[^/]+$",
    "/new--year",
    "/Carnival2k23/P/[^/]+$",
    "Carnival2k23/T/[^/]+$",
    "/my--bid",
    "/",
    "/FavorateHangoutViewall",
    "/TableBookingViewall",
    "/GroupBookingViewall",
    "/PopularPackageViewall",
    "/Package-view-all",
    "/BookATableViewAll",
  "/Carnivals",
  "/artist-view-all",
  "/result?",
  ];
  const shouldShowNavbar = () => {
    return !hideNavbarRoutes.some((route) =>
      new RegExp(`^${route}(\/|$)`).test(location.pathname)
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return shouldShowNavbar() ? <Navbar /> : null;
}
function FooterConditional() {
  const location = useLocation();
  const hideFooterRoutes = [
    "/my--profile",
    "/my-Favorite--ham",
    "/my--booking",
    "/my--Notification",
    "/booknow--table--cart/[^/]+$",
    "/nextPage",
    "/summerypage",
    "/tablebooking--pass/[^/]+$",
    "/event-book/[^/]+/[^/]+$",
    "/success",
    "/artist-view-all",
  ];

  const shouldShowFooter = () => {
    return !hideFooterRoutes.some((route) =>
      new RegExp(`^${route}(\/|$)`).test(location.pathname)
    );
  };

  useEffect(() => {
     window.scrollTo(0, 0);
  }, [location]);

  return shouldShowFooter() ? <Footer /> : null;
}

function Routerpage() {
  return (
    <Router>
      <div className="router-page">
        <div className="home-page-design">
          <NavbarConditional />
        </div>
        <Routes className="">
          <Route
            path="/:city/:area/:clubname"
            element={<Clubaboutuspagesearch />}
          ></Route>
          <Route exact path="/" element={<App />}></Route>
          <Route exact path="/artist-page" element={<OurArtist />}></Route>
          <Route exact path="/club-about-us" element={<Clubpage />}></Route>
          <Route path="/refund-policy" element={<RefundPolicy />}></Route>
          <Route path="/artist-agreement" element={<ArtistAgreement />}></Route>
          <Route path="/club-agreement" element={<ClubAgreement />}></Route>
          <Route path="/as-an-artist" element={<Asanartist />}></Route>
          <Route path="/party-witty-aboutus" element={<AboutusPage />}></Route>
          <Route path="/book-as-a-artist" element={<BookanArtist />}></Route>
          <Route path="/as-club" element={<PartnerwithUs />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/our-contact-page" element={<ContactUs />}></Route>
         
          <Route path="/event-book/:club_id/:id" element={<EventBookNow />}></Route>
        
          <Route path="/cracker" element={<Fireworks />}></Route>
          <Route
            path="/happening-party-page"
            element={<Happeningparty />}
          ></Route>
          <Route
            path="/happening-view-page"
            element={<HappeningViewButton />}
          ></Route>
          <Route path="/music-view-page" element={<MusicViewButton />}></Route>
          <Route path="/comming-soon" element={<CommingSoon />}></Route>
          <Route
            path="/business-registration"
            element={<RegistrationLoginForm />}
          ></Route>
          
          <Route path="/privacy-policy" element={<Privacypolicy />}></Route>
          <Route
            path="/term-conditions-policy"
            element={<Termscondition />}
          ></Route>
          <Route
            path="/cancellation-policy"
            element={<Cancellationpolicy />}
          ></Route>
          <Route path="/payment" element={<Paymentpage />}></Route>
          <Route path="/success" element={<Successpage />}></Route>
          <Route path="/our-artist" element={<Successpage />}></Route>
          <Route path="/my-booking-history" element={<MyBookingHistory/>}></Route>
          <Route exact path={ "/party-packages-delhi-ncr-view-all"} element={<GroupBookingViewall/>}></Route>
          <Route exact path="/:city/:area/:club/:packageName" element={<PackageAboutUs/>}></Route>
          <Route path="/thank-you" element={<ThankewPage />}></Route>
          <Route path="/success" element={<Successpage />}></Route>
          <Route path="/favourite-club" element={<Favclub />}></Route>
       
          <Route path="/Product-Pricing" element={<PackagePrice />}></Route>
          <Route path="/my--booking" element={<MyBooking />}></Route>
          <Route path="/my--profile" element={<MyProfile />}></Route>
          <Route path="/my--Notification" element={<MyNotification />}></Route>
          <Route path="/my-Favorite--ham" element={<MyFavorite />}></Route>
         
          <Route path="/event-delhi-ncr-view-all" element={<EventViewAll/>}></Route>
         
          <Route path="/thank--you--page" element={<NewThankewPage />}></Route>
  
          <Route path="/:cityname/Table-booking-reservations" element={<FavorateHangoutViewall/>}></Route>
          <Route path="/party-packages/:id" element={<PopularPackageView/>}></Route>
          <Route path="/FavorateHangoutThumbnail" element={<FavorateHangoutThumbnail/>}></Route>
          <Route path="/TableBookingViewall" element={<TableBookingViewall/>}></Route>
          <Route path="/GroupBookingViewall" element={<GroupBookingViewall/>}></Route>
          <Route path="/party-packages" element={<PopularPackageViewall/>}></Route>
          <Route path="/book-a-table-delhi-ncr-view-all" element={<BookATableViewAll/>}></Route>
          <Route path="/newly-added-club-delhi-ncr-view-all" element={<NewlyAddedClubViewAll/>}></Route>
       
          <Route path="/Payment-Failed" element={<HoliFailed/>}></Route>
          <Route path="/ClubDetail/:id" element={<ClubDetails/>}></Route>
          <Route path="/result?" element={<SearchResult/>}></Route>
          <Route path="/:city/:area/club/:clubname" element={<ClubFullDetails/>}></Route>
          <Route path="/:city/:area/:club/event/:title" element={<EventAboutUS />} />
          <Route path="/:city/:area/:club/recommendevent/:title" element={<EventAboutUS />} />
          <Route path="/blog" element={<Blog/>}></Route>
          <Route path="/artist-view-all" element={<ArtistCard/>}></Route>
          <Route path="/blog/:title" element={<BlogDetails/>}></Route>
          <Route path="/club-detail" element={<ClubDetail/>}></Route>
        </Routes>
        <div className="footer-section">
          <FooterConditional />
        </div>
      </div>
    </Router>
  );
}
export default Routerpage;
